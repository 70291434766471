<template>
    <div class="flex border border-beige-dark rounded-lg w-full h-full">
        <div class="flex-none bg-beige w-24 flex items-center justify-center text-4xl">
            <i 
                class="fas fa-users" 
            />
        </div>
        <div class="flex-grow p-8">
            <h3 class="text-lg font-bold leading-tight">{{portal.name}}</h3>
            <div class="">
                Tilgangskode:
                {{portal.accessCode}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        portal: {
            type: Object,
            required: true,
        }
    },
}
</script>